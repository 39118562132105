<!-- 帖子详情 -->
<template>
  <div class="content">
    <!--  文章内容-->
    <div class="article_cont">
      <div class="header">
        <img src="@static/forum/tou.jpg" />
        <div class="left">
          <span class="name">老汉醉酒</span>
          <span class="sign">该用户暂未设置个性签名</span>
        </div>
        <div class="right">
          <span class="attention" v-if="attention=='flase' ">+关注</span>
          <span class="ignore" v-else>已关注</span>
        </div>
      </div>
      <div class="cont">
        <b>前言</b>
        <div>说起与奔驰C200L的相遇，是在2018年9月了，提车后一直很忙，没时间与大家分享提车作业，这次见机偷得半日闲的时间，陪媳妇出去采摘草莓，顺便也把提车作业与大家分享了！</div>
        <div>拍摄介绍</div>
        <div>
          1、拍摄地点：草棚和自家住房路边；
          2、拍摄设备：无专业设备，希望大家不要吐槽，一个跟随我2年多的iphone 6 plus；
          3、拍摄目标：爱人、爱车。
        </div>
        <img src="@static/forum/tou.jpg" />
        <div>当初，我媳妇还纠结立标还大标，过想奔驰大标装瓜子梗，加大标运动多，而且也比较适合年轻人，立标太商务化了，适合我们这样人，所以果断抉择了大标。</div>
        <div class="like_list">
          <span class="box">
            <span class="iconfont icondianzan"></span>
            <span class="num">8</span>
          </span>
          <span class="list">
            <span>其乐rr,</span>
            <span>西喜爱,</span>
            <span>45ddf0,</span>
            <span>稀饭哟,</span>
            <span>清风,</span>
            <span>话语,</span>
            <span>哎嗨嗨,</span>
            <span>听风来,</span>
            <span>清风明月,</span>
          </span>
        </div>
      </div>
      <!-- 广告 -->
      <div class="adversit">
        <img src="@static/forum/tou.jpg" />
      </div>
      <!-- 推荐模板 -->
      <div class="recommend">
        <div class="recommend_header">
          <div class="r_h_left">模板推荐</div>
          <div class="r_h_right">更多 ></div>
        </div>
        <div class="cont">我是新闻啊</div>
      </div>
      <div class="empty"></div>
    </div>
    <!-- 全部评论 -->
    <COMMENT :commentArray="commentArray"></COMMENT>
    <div class="empty"></div>
    <!-- 底部 -->
    <div class="bottom">
      <!-- 都是点击按钮了 -->
      <div class="buttons">
        <input type="span" placeholder="  写评论..." />
        <input accept="image/*" type="file" capture="camera" />
        <span class="iconfont iconicon-"></span>
        <span class="iconfont icondianzan"></span>
        <span class="iconfont iconxingxing"></span>
        <span class="iconfont iconweb-icon-"></span>
      </div>
      <!-- 评论编辑页 -->
      <div :class="butShow?'comment_editor current':'comment_editor'">
        <div class="comment_editor_top">
          <span class="cancle">取消</span>
          <span class="title">写评论</span>
          <span class="confirm">确认</span>
        </div>
        <div class="comment_editor_cont" contenteditable="true"  >{{textConent}}</div>
        <Discuss :ftextConent = "textConent"></Discuss>
      </div>
    </div>
  </div>
</template>

<script>
import COMMENT from "@components/public/comment";
import Discuss from "@components/public/discuss";
export default {
  data() {
    return {
      commentArray: [
        {
          picture: require("@static/forum/tou.jpg"),
          name: "有点小肉肉",
          cont: "如果选择梦想陪伴余生，倒是能选择的变得更少了。是得还是失？",
          time: "昨天",
          floor: 3
        },
        {
          picture: require("@static/forum/tou.jpg"),
          name: "有点小肉肉",
          cont: "如果选择梦想陪伴余生，倒是能选择的变得更少了。是得还是失？",
          time: "昨天",
          floor: 3
        }
      ],
      attention: false,
      textConent: "",
      butShow: false
    };
  },

  components: {
    COMMENT,
    Discuss
  }
};
</script>
<style lang='scss' scoped>
.content {
  // 文章内容
  .article_cont{
    border-top:1px solid  rgba(244,244,244,1);
    background: rgba(244,244,244,1);
    .header{
      height:50px;
      background:rgba(255,255,255,1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size:14px;
      padding:7px 0;
      img{
        width:40px;
        height:40px;
        padding:0px 12px;
        border-radius:50%;
      }
      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name{
          font-size:17px;
          color:#333333;
        }
        .sign{
          font-size:9px;
          color:#8C8C8C;
        }
      }
      .right{
        width:22%;
                  
        span{
          font-size:11px;
          padding:5px 10px;
          border-radius: 16px;
        }
        .attention {
          color: #fff;
          background: rgba(255, 128, 0, 1);
        }
        .ignore{
          border:1px solid rgba(255,128,0,1);
          color:rgba(255,128,0,1);
        }
      }

      background: #fff;
    }
    .cont{
      background:#fff;
      font-size:14px;
      padding:0px 12px;
      div{
        padding:12px 0;
      }
      .like_list{
        border-top:1px solid  rgba(244,244,244,1);
        .box{
          color:#FF6600 ;
        }
        .list{
          color:#007AFd;
          span{
            padding-right:8px;
          }
        }
      }
      img {
        width: 100%;
      }
    }
    // 广告
    .adversit{
      height:120px;
      background:rgba(255,255,255,1);
      margin:7px 0;
      padding:9px 12px;
      img{
        width:100%;
        height:100%;
      }
    }
    // 模板推荐
    .recommend{
      padding:8px 12px;
      background: #fff;
      .recommend_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height:15px;
        font-size:15px;
        .r_h_right{
          color:#ff6600;
        }
        .r_h_left{
          border-left:1px solid #ff6600;
          padding-left:3px;
        }
      }
    }
  }
  // 我就是一个空的底部
  .empty{
    height:55.5px;
    background: #fff;
  }
  // 底部
  .bottom {
    width: 100%;
    position: fixed;
    bottom: 0px;
    //按钮页了
    .buttons{
      height:55.5px;
      background:rgba(255,255,255,1);
      position:abouslte;
      left:0;
      bottom: 0;
      font-size:16px;
      display: flex;
      justify-content: center;
      align-items: center;
      display:none;
      input{
        height:32.5px;
        background:rgba(244,244,244,1);
        border-radius:5px;
        font-size:12px;
        margin:0 12px;
        flex:1;
      }
      .iconfont {
        // width:34px;
        font-size:21px;
        padding-left:16px;
      }
    }
    // 评论编辑
    .comment_editor{
      width:100%;
      min-height:150px;
      z-index:11;
      position: absolute;;
      left:0;
      bottom:0px;
      background:#fff;
      // 头部
      .comment_editor_top {
        display: flex;
        justify-content: space-between;
        height:33px;
        line-height:33px;
        font-size:13px;
        padding:12px;
        .title{
          font-weight: 600;
          font-size:16px;
        }
        .confirm {
          color: #ff6600;
        }
      }
      // 内容
      .comment_editor_cont{
          padding:14px 11px;
          height: 90px;
          background:rgba(244,244,244,1);
          background-clip: content-box;
      }
    }
  }
}
</style>