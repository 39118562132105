<!-- 评论列表展示组件 -->
<template>
  <div class="comment">
		<!-- 评论的头部 -->
		<div class="comment_header">
			<div class="c_h_left">
				<span class="name">全部评论</span>
				<span class="only">只看楼主</span>
			</div>
			<div class="c_h_right">
				<span>最赞</span>
				<span class="order">正序<span class="iconfont iconjiantou_shang"></span></span>
			</div>
		</div>
		<div class="comment_cont">
			<div class="c_c_card" v-for="(items, index) in commentArray" :key=index>
				<div class="c_c_card_left">
					<img :src="items.picture" />
				</div>
				<div class="c_c_card_right">
					<div class="c_c_card_right_top">
						<span class="name">{{items.name}}</span>
						<span class="praise iconfont icondianzan">赞</span>
					</div>
					<div class="c_c_card_right_center">
						{{items.cont}}
					</div>
					<div class="c_c_card_right_bottom">
						<span class="left">
							<span class="time">
								{{items.time}}
							</span>
							<span class="stairs">
								{{items.floor}}楼
							</span>
						</span>
						<span class="right">
							<span class="iconfont iconicon- reply">回复</span>
							<span class="report">举报</span>
						</span>
					</div>
				</div>
			</div>
			<div class="hint">没有更多了~</div>
		</div>
			
	</div>
</template>

<script>
export default {
	data() {
		return {
			// lists: []
		}
	},
  props:{
		commentArray:{
			type:Array
		}
	},
	mounted(){
		// this.lists = this.commentArray
	}
}

</script>
<style lang='scss' scoped>
// 评论
	%background-padding{
		background:#fff;
		padding:0 12px;
	}
	.comment{
		background: #fff;
		margin-top: 7px;
		font-size:15px;
		background:rgba(244,244,244,1);
		.comment_header{
			height: 40px;
			display:flex;
			justify-content: space-between;
			align-items: center;
			@extend %background-padding;
			span{
				padding-right:15px;
			}
			.c_h_left{
				border-left:1px solid #ff6600;
				padding-left:3px;
				.only{
					background:rgba(255,255,255,1);
					border:1px solid rgba(125,125,125,1);
					border-radius:3px;
					text-align:center;
					padding:2px;
					color:rgba(125,125,125,1);
					font-size:13px;
				}
			}
			.c_h_right{
				color:rgba(125,125,125,1);
				.order{
					color:#ff6600;
					padding:0;
					.iconfont{
						font-size:4px;
						padding:4px;
					}
				}
			}
		}
		// 评论的内容 
		.comment_cont{
			.c_c_card{
				display: flex;
				justify-content: space-between;
				border-bottom:1px solid rgba(244,244,244,1);
				padding:7px 12px;
				background:#fff;
				// @extend %backgro/und-padding;
				.c_c_card_left{
					width:43px;
					padding-right:10px;
					img{
						width:35px;
						height:35px;
					}
				}
				.c_c_card_right{
					flex:1;
					font-size:17px;
					.c_c_card_right_top{
						height:35px;
						display:flex;
						justify-content: space-between;
						align-items: center;
						.praise{
							font-size:11px;
							color:rgba(153,153,153,1);
						}
					}
					.c_c_card_right_center{
						font-size:15px;
						padding:7px 0;
					}
					.c_c_card_right_bottom{
						display:flex;
						justify-content: space-between;
						font-size:12px;
						color:rgba(153,153,153,1);
						span{
							padding-right:7px;
						}
					}
					
				}
			}
			// 加载提示
			.hint{
				height:37px;
				line-height: 37px;
				font-size:14px;
				text-align:center;
				background:rgba(244,244,244,1);
				color:rgba(140,140,140,1);
				border:1px solid red;
				border-radius: -7px;
			}
		}
	}
</style>